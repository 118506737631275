<template>

  <b-container fluid>

    <b-jumbotron class="mt-4">
        <template #header>Financiar</template>
    </b-jumbotron>
  </b-container>
</template>

<script>

export default {
  name: 'Finances'
}
</script>
