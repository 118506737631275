<template>
  <div class="container-fluid">
    <b-jumbotron class="mt-4">
      <template #header>Oreder finances</template>
      <b-row>
        <b-col>
          <label>Start date</label>
          <b-datepicker v-model="filters.date_from" size="sm"></b-datepicker>
        </b-col>
        <b-col>
          <label>End date</label>
          <b-datepicker v-model="filters.date_to" size="sm"></b-datepicker>
        </b-col>
        <b-col>
          <label>Driver</label>
          <UserSelect v-model="filters.driver" :roles="['driver']"></UserSelect>
        </b-col>
        <b-col>
          <label>Status</label>
          <b-select v-model="filters.driver_payment" size="sm">
            <b-select-option :value="null">-</b-select-option>
            <b-select-option value="paid">Paid</b-select-option>
            <b-select-option value="unpaid">Unpaid</b-select-option>
          </b-select>
        </b-col>
        <b-col v-if="Array.isArray(results)">
          <label>&nbsp;</label>
          <b-button @click="exportAsPdf" variant="info" size="sm" class="d-block">Export as PDF</b-button>
        </b-col>
      </b-row>
    </b-jumbotron>
    <div v-if="Array.isArray(results)">
      <table id="results-table" class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Indicativ</th>
            <th>Name</th>
            <th>Account number</th>
            <th>Card payments</th>
            <th>Protocol payments</th>
            <th>Discount payments</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in results" :key="'tr-' + item.driver_id" @click="showDriverOrders(item)">
            <td>{{ item.driver_id }}</td>
            <td>{{ item.indicativ }}</td>
            <td>{{ item.driver_name }}</td>
            <td>{{ item.viva_account_number }}</td>
            <td>{{ item.card_paid.toFixed(2) }} paid / {{ item.card_unpaid.toFixed(2) }} unpaid</td>
            <td>{{ item.protocol_paid.toFixed(2) }} paid / {{ item.protocol_unpaid.toFixed(2) }} unpaid</td>
            <td>{{ item.discount_paid.toFixed(2) }} paid / {{ item.discount_unpaid.toFixed(2) }} unpaid</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="text-right">
              <b>TOTAL FOR {{ results.length }} DRIVERS</b>
            </td>
            <td>{{ totals.cardPaid.toFixed(2) }} paid / {{ totals.cardUnpaid.toFixed(2) }} unpaid</td>
            <td>{{ totals.protocolPaid.toFixed(2) }} paid / {{ totals.protocolUnpaid.toFixed(2) }} unpaid</td>
            <td>{{ totals.discountPaid.toFixed(2) }} paid / {{ totals.discountUnpaid.toFixed(2) }} unpaid</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div v-else class="text-center py-3">
      <b-spinner v-if="filters.date_from && filters.date_to"></b-spinner>
      <b-alert v-else variant="warning" show>Please choose dates range</b-alert>
    </div>
    <b-modal modal-class="test" v-if="driver !== null" visible @hidden="driver = null" :title="driver.indicativ + ' - ' + driver.driver_name" size="xxl" hide-footer>
      <GetOrders
        :user-id="driver.driver_id"
        :height="600" :date-start="filters.date_from"
        :date-end="filters.date_to"
        include-driver-payments
      ></GetOrders>
    </b-modal>
  </div>
</template>

<script>
import UserSelect from '@/components/common/UserSelect'
import config from '@/config'
import GetOrders from '@/components/orders/GetOrders'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

export default {
  name: 'OrderFinanceReports',
  components: { GetOrders, UserSelect },
  data () {
    return {
      filters: {
        date_from: null,
        date_to: null,
        driver: null,
        driver_payment: null,
        sort: 'indicativ|asc'
      },
      results: null,
      driver: null
    }
  },
  computed: {
    filteredFilters () {
      const filters = Object.assign({}, this.filters)
      filters.driver_id = filters.driver !== null ? filters.driver.id : null
      delete filters.driver
      return filters
    },
    totals () {
      const totals = {
        cardPaid: 0,
        cardUnpaid: 0,
        protocolPaid: 0,
        protocolUnpaid: 0,
        discountPaid: 0,
        discountUnpaid: 0
      }

      this.results.forEach((row) => {
        totals.cardPaid += row.card_paid
        totals.cardUnpaid += row.card_unpaid
        totals.protocolPaid += row.protocol_paid
        totals.protocolUnpaid += row.protocol_unpaid
        totals.discountPaid += row.discount_paid
        totals.discountUnpaid += row.discount_unpaid
      })

      return totals
    }
  },
  methods: {
    showDriverOrders (driver) {
      this.driver = driver
    },
    exportAsPdf () {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: 'a4'
      })
      doc.autoTable({
        html: '#results-table'
      })
      doc.save('report-driver-payments.pdf')
    }
  },
  watch: {
    filteredFilters: {
      handler (filters) {
        if (filters.date_from && filters.date_to) {
          this.results = null
          this.$axios.get(config.baseApiUrl + '/api/orders-finance', {
            params: filters
          }).then((response) => {
            this.results = response.data.data
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
